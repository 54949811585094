import React, { useState, useEffect, useRef } from "react";
import { WebApimanager } from "../../WebApiManager";
import "../../Widgets/Widgets.css";
import {
  GetTimeYearToMin,
  GetTimein12HrFormat,
} from "../../Widgets/CommonWidgets";
import swal from "sweetalert";
import SnackAlert from "../../Widgets/SnackbarAlert";
import { useRecoilValue } from "recoil";
import { useLocation, useNavigate } from "react-router";
import { IconInCircle } from "../../Widgets/Icons";
import { FiTool, FiPaperclip } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { BiPencil } from "react-icons/bi";
import {
  GetCompleteAddress,
  TimeRangeValidation,
} from "../../Widgets/Forms/FieldDependants";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import {
  AiOutlineCheckSquare,
  AiOutlineCloseSquare,
  AiOutlinePlayCircle,
} from "react-icons/ai";
import {
  BsThreeDotsVertical,
  BsFillArrowLeftCircleFill,
  BsArrowLeftCircleFill,
  BsArrowRightCircleFill,
} from "react-icons/bs";
import { userData, companyId } from "../../atoms";
import { ButtonCustom, ButtonGrayOutline } from "../../Widgets/Buttons";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { FaWrench } from "react-icons/fa";
import { SlPaperPlane } from "react-icons/sl";
import { AiOutlineMessage, AiFillCloseCircle } from "react-icons/ai";
import { Loader } from "../../Widgets/notificationFeedbacks";
import CreateMaintenanceRequest from "./CreateMaintenanceRequest";
import { PiTreeStructureDuotone, PiWarningOctagon } from "react-icons/pi";
import { Dialog } from "@mui/material";
import { MdDeleteOutline } from "react-icons/md";
import { InputFileField, InputTextArea } from "../../Widgets/Forms/InputFields";
import { ButtonBlue } from "../../Widgets/Buttons";

const MaintenanceRequestDetails = (props) => {
  let webApi = new WebApimanager();
  let location = useLocation();
  let ticketId = props.turnoverTaskID || location.state.ticketId;
  const isTurnoverTicket = props.turnoverTaskID ? true : false;
  const companyID = useRecoilValue(companyId);
  let navigate = useNavigate();
  const userInfo = useRecoilValue(userData);
  const [maintenanceDetails, setMaintenanceDetails] = useState("");
  const [allComments, setAllComments] = useState([]);
  const [unitAddress, setUnitAddress] = useState("");
  const [isTurnover, setIsTurnover] = useState(false);
  const [openSubTaskRequest, setOpenSubtaskRequest] = useState(false);
  const [allSubtasks, setAllSubtasks] = useState([]);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [maintenanceStatues, setMaintenanceStatues] = useState([]);
  const [assignToOptions, setAssignToOptions] = useState([]);
  const [vendorOptions, setVendorsOptions] = useState([]);
  const [publicImages, setPublicImages] = useState([]);
  const [privateImages, setPrivateImages] = useState([]);
  const [publicVideos, setPublicVideos] = useState([]);
  const [privateVideos, setPrivateVideos] = useState([]);

  const [publicMedia, setPublicMedia] = useState(true);

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);

  const [openNotesForm, setOpenNotesForm] = useState(false);
  const [selectedMaintenanceStatus, setSelectedMaintenanceStatus] =
    useState("");
  const [maintenanceMediaForm, setMaintenanceMediaForm] = useState({
    open: false,
    mediaType: "",
  });

  const [openGallery, setOpenGallery] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: "Loading...",
    });
    getMaintenanceDetails();
    getMaintenanceComments();
    window.scroll(0, 0);
    getPriorityOptions();
    getAssignToOptions();
  }, []);

  const getMaintenanceDetails = () => {
    webApi
      .get(`/maintenance/tickets/${ticketId}&${userInfo.userRole}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          getMaintenanceMediaDetails();
          setMaintenanceDetails(res.data.maintenance);
          if (
            res.data.maintenance.requestType !== "Turnover" &&
            (!categoryOptions.length || subCategoryOptions.length)
          ) {
            getCategoryOptions(res.data.maintenance.category);
          }
          if (!maintenanceStatues.length) {
            getMaintenanceTicketStatusOptions(res.data.maintenance);
          }
          if (res.data.maintenance.requestType === "Turnover") {
            setIsTurnover(true);
          }
          setAllSubtasks(res.data.subtasks || []);
          setUnitAddress(res.data.unitAddress);
        }
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Maintenance Details, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getMaintenanceMediaDetails = () => {
    webApi
      .get(`/maintenance/media/${ticketId}&${userInfo.userRole}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          // if (res.data.maintenance_images !== null) {
          //   setImages(res.data.maintenance_images);
          // }

          if (res.data.maintenance_images !== null) {
            const publicImages = res.data.maintenance_images.filter(
              (image) => image.folder === "Public"
            );
            const privateImages = res.data.maintenance_images.filter(
              (image) => image.folder === "Private"
            );

            setPublicImages(publicImages);
            setPrivateImages(privateImages);
          }

          // if (res.data.maintenance_videos !== null) {
          //   setVideos(res.data.maintenance_videos);
          // }

          if (res.data.maintenance_videos !== null) {
            const publicVideos = res.data.maintenance_videos.filter(
              (video) => video.folder === "Public"
            );
            const privateVideos = res.data.maintenance_videos.filter(
              (video) => video.folder === "Private"
            );

            setPublicVideos(publicVideos);
            setPrivateVideos(privateVideos);
          }
        }
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Maintenance Media Details, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getMaintenanceComments = () => {
    webApi
      .get(`/maintenance/tickets/${ticketId}/comments/pmcore`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAllComments(res.data);
        }
        setApiProcessing({ loader: false, message: "" });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: "" });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Maintenance Details, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getCategoryOptions = (categoryValue) => {
    // let data = {
    //   description: desData,
    // };

    webApi
      .get("/maintenance/categories")
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setCategoryOptions(res.data);

          const selectedCategoryObject = res.data.find(
            (category) => category.categories === categoryValue
          );
          if (selectedCategoryObject) {
            getSubCategories(selectedCategoryObject.id);
          }
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to load Categories Options, Please try again later.`,
            "error"
          );
        }
      });
  };

  // Handle category selection change
  const handleCategoryChange = (selectedCategory) => {
    const selectedCategoryObject = categoryOptions.find(
      (category) => category.categories === selectedCategory
    );
    if (selectedCategoryObject) {
      getSubCategories(selectedCategoryObject.id);
    }
    setMaintenanceDetails({
      ...maintenanceDetails,
      category: selectedCategory,
      subCategory: "",
    });
  };

  //Fetch SubCategories
  const getSubCategories = (categoryId) => {
    setApiProcessing({ loader: true, message: `Gathering Sub-Categories` });
    webApi
      .get(`/maintenance/subcategories/${categoryId}`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setSubCategoryOptions(res.data);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to load Sub-Categories, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getPriorityOptions = () => {
    webApi
      .get("/maintenance/tickets/list/priority")
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setPriorityOptions(res.data.priority);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to load Priority Options, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getAssignToOptions = () => {
    webApi
      .get(`/maintenance/tickets/task/assignee/${companyID}`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAssignToOptions(res.data.others);
          setVendorsOptions(res.data.vendors);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to load Vendor and Assign to Options, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getMaintenanceTicketStatusOptions = (newMaintenanceDetails) => {
    webApi
      .get("/maintenance/tickets/list/status")
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setMaintenanceStatues(res.data.maintenanceStatus);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to load Maintenance Status Options, Please try again later.`,
            "error"
          );
        }
      });
  };

  const updateMaintenanceDetails = (dataProp, triggerComments) => {
    setApiProcessing({
      loader: true,
      message: "Updating Maintenance Details...",
    });
    let data = dataProp;
    webApi
      .put("/maintenance/tickets/" + ticketId, data)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setMaintenanceDetails(res.data);
          swal(
            "Success",
            "Successfully updated Maintenance Request Details",
            "success"
          );
        }
        setApiProcessing({
          loader: false,
          message: ``,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: ``,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Update Maintenance Details, Please try again later.`,
            "error"
          );
        }
      });
  };

  const updateMaintenanceTicketStatus = (
    target,
    key,
    value,
    ticketId,
    commentNote
  ) => {
    setApiProcessing({
      loader: true,
      message: `Updating ${target} Status...`,
    });

    const requestData = {
      status: value,
      comment: commentNote,
    };

    webApi
      .put(`/maintenance/tickets/status/${ticketId}`, requestData)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          changeStatusColor(target, key, value, ticketId);
          setAllComments(res.data);
          swal("Success", `Successfully Updated ${target} Details`, "success");
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Update ${target} Status, Please try again later.`,
            "error"
          );
        }
      });
  };

  const updateMedia = (files, fileType, notes) => {
    setApiProcessing({
      loader: true,
      message: "Uploading Media...",
    });
    let MediaData = [];

    for (var i = 0; i < files.length; i++) {
      MediaData.push({
        mediaFileName: files[i].name,
        uploadedByID: userInfo.businessAddress.ClientId,
        userType: userInfo.userRole,
        notes: notes,
        folder: "Private",
      });
    }

    if (files.length) {
      let data = { ...maintenanceDetails };
      data.media = MediaData;
      webApi.put("/maintenance/tickets/" + ticketId, data).then(async (res) => {
        if (res.data.errorCode) {
          setApiProcessing({
            loader: false,
            message: "Loading...",
          });
          swal("Failure", res.data.errorMessage, "error");
        } else {
          let failedToUploadMediaCount = 0;
          const uploadPromises = files.map(async (file, i) => {
            handleSnackbar(
              "info",
              "Uploading " + (i + 1) + "/" + files.length + " of Selected Files"
            );

            const metaData = {
              filename: file.name,
              mediatype: file.type,
            };

            return webApi
              .uploadFile(res.data.media[i].fileObjectKey, metaData, file)
              .then((res) => {})
              .catch((error) => {
                failedToUploadMediaCount++;
                if (error.customErrorMessage) {
                  swal("Error", error.customErrorMessage, "error");
                } else {
                  swal(
                    "Error",
                    `Failed to upload ${file.name}. Please try again later.`,
                    "error"
                  );
                }
              });
          });

          Promise.all(uploadPromises)
            .then((results) => {
              if (failedToUploadMediaCount !== files.length) {
                getMaintenanceDetails();
              }
              setApiProcessing({
                loader: false,
                message: "Loading...",
              });
            })
            .catch((error) => {
              setApiProcessing({
                loader: false,
                message: "Loading...",
              });
            });
        }
      });
    }
  };

  const addComment = (comment, commentType) => {
    let data = {
      maintenanceID: maintenanceDetails.ID,
      role: userInfo.userRole,
      roleID: userInfo.businessAddress.ClientId,
      comment: comment,
      isPublic: commentType,
    };
    webApi
      .post("/maintenance/tickets/comments", data)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAllComments([...allComments, res.data]);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Add Comment, Please try again later.`,
            "error"
          );
        }
      });
  };

  const deleteComment = (ID) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this Comment!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete("/maintenance/tickets/comments/" + ID)
          .then(async (res) => {
            if (res.data.errorCode !== 200) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              setAllComments(
                allComments.filter((comment) => comment.ID !== ID)
              );
              swal("Success", res.data.errorMessage, "success");
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Oops! Failed to Delete Comment, Please try again later.`,
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const handleMaintenanceDetailsOnChange = (e, categoryChange) => {
    e.preventDefault();
    setMaintenanceDetails({
      ...maintenanceDetails,
      [e.target.name]: e.target.value,
    });

    let data = { ...maintenanceDetails };
    data.media = "";

    if (maintenanceDetails.hasOwnProperty(e.target.name)) {
      data[e.target.name] = e.target.value;
    }

    if (categoryChange) {
      data.category = maintenanceDetails.category;
    }

    updateMaintenanceDetails(data);
  };

  const handleVendorOnChange = (e) => {
    e.preventDefault();

    setMaintenanceDetails({
      ...maintenanceDetails,
      vendorID: parseInt(e.target.value),
    });

    const filteredOptions = vendorOptions.filter(
      (option) => option.roleId === parseInt(e.target.value)
    );

    let data = { ...maintenanceDetails };
    data.media = "";

    data.vendorID = parseInt(e.target.value);
    data.vendor = filteredOptions ? filteredOptions[0]?.name : "";
    data.status = "Pending";

    const triggerComments = true;

    updateMaintenanceDetails(data, triggerComments);
  };

  const handleTaskAssignedOnChange = (e) => {
    e.preventDefault();
    let newAssignValues = assignToOptions.filter(
      (option) => option.roleId === parseInt(e.target.value)
    );

    setMaintenanceDetails({
      ...maintenanceDetails,
      taskAssignedRole: newAssignValues[0].role,
      taskAssignedRoleID: newAssignValues[0].roleId,
      taskAssigned: newAssignValues[0].name,
    });

    let data = { ...maintenanceDetails };
    data.taskAssignedRole = newAssignValues[0].role;
    data.taskAssignedRoleID = newAssignValues[0].roleId;
    data.taskAssigned = newAssignValues[0].name;
    data.media = "";
    updateMaintenanceDetails(data);
  };

  const handleMediaDelete = (e) => {
    if (!selectedImages.length && !selectedVideos.length) {
      handleSnackbar("warning", "No Media Selected !");
    } else {
      swal({
        title: "Are you sure?",
        text: "You want to delete selected Media!",
        icon: "warning",
        buttons: ["Cancel", "Yes, delete it!"],
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          setApiProcessing({
            loader: true,
            message: "Processing Delete Request...",
          });
          let data = {
            media: [...selectedImages, ...selectedVideos],
          };

          webApi
            .post(
              "/maintenance/tickets/delete/document/maintenance/" +
                maintenanceDetails.ID,
              data
            )
            .then(async (res) => {
              if (res.data.errorCode) {
                swal("Error", res.data.errorMessage, "error");
              } else {
                setAllComments(res.data);
                getMaintenanceMediaDetails();
                // setImages(
                //   images.filter((item) => !selectedImages.includes(item))
                // );
                // setVideos(
                //   videos.filter((item) => !selectedVideos.includes(item))
                // );
                setSelectedImages([]);
                setSelectedVideos([]);
                swal(
                  "Success",
                  "Successfully deleted the selected Media",
                  "success"
                );
              }
              setApiProcessing({
                loader: false,
                message: "Loading...",
              });
            })
            .catch((error) => {
              setApiProcessing({
                loader: false,
                message: "Loading...",
              });
              if (error.customErrorMessage) {
                swal("Error", error.customErrorMessage, "error");
              } else {
                swal(
                  "Error",
                  "Failed to Delete Media, Please try again later.",
                  "error"
                );
              }
            });
        } else {
          return;
        }
      });
    }
  };

  const changeStatusColor = (target, key, newStatus, ticketId) => {
    if (target === "Subtask") {
      const updatedAllSubtasks = allSubtasks.map((item) => {
        if (item.ID === ticketId) {
          const matchingStatus = maintenanceStatues.find(
            (statusItem) => statusItem.status === newStatus
          );

          if (matchingStatus) {
            item.status = newStatus;
            item.statusColor = matchingStatus.color;
          }
        }
        return item;
      });

      setAllSubtasks(updatedAllSubtasks);
    } else {
      maintenanceStatues.map((item) => {
        if (item.status === newStatus) {
          setMaintenanceDetails({
            ...maintenanceDetails,
            [key]: newStatus,
            statusColor: item.color,
          });
        }
      });
    }
  };

  const handleSnackbar = (type, message) => {
    setOpenSnackbar(true);
    setSnackbarType(type);
    setSnackbarMessage(message);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleGallery = (index) => {
    setGalleryIndex(index);
    setOpenGallery(true);
  };

  const handleSelectedMedia = (mediaType, checked, src) => {
    if (mediaType === "images") {
      if (checked) {
        setSelectedImages([...selectedImages, src]);
      } else {
        setSelectedImages(selectedImages.filter((media) => media !== src));
      }
    } else if (mediaType === "videos") {
      if (checked) {
        setSelectedVideos([...selectedVideos, src]);
      } else {
        setSelectedVideos(selectedVideos.filter((media) => media !== src));
      }
    }
  };

  //Delete Subtasks
  const handleDeleteSubtask = (subtask) => {
    swal({
      title: "Are you sure?",
      text: `Do you want to delete ${subtask.woid} Subtask!`,
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        setApiProcessing({
          loader: true,
          message: `Removing Selected Subtask...`,
        });
        webApi
          .delete(`/maintenance/tickets/${subtask.ID}`)
          .then((res) => {
            if (res.data.errorCode !== 200) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              const updatedSubtasks = allSubtasks.filter(
                (item) => item !== subtask
              );
              setAllSubtasks(updatedSubtasks);
              swal("Success", res.data.errorMessage, "success");
            }
            setApiProcessing({
              loader: false,
              message: ``,
            });
          })
          .catch((error) => {
            setApiProcessing({
              loader: false,
              message: ``,
            });
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Failed to Delete selected Subtask, Please try again later.`,
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  return (
    <>
      <SnackAlert
        open={openSnackbar}
        close={handleSnackbarClose}
        type={snackbarType}
        message={snackbarMessage}
      />

      {openGallery && (
        <MaintenanceMediaGallery
          images={publicMedia ? publicImages : privateImages}
          videos={publicMedia ? publicVideos : privateVideos}
          index={galleryIndex}
          openGallery={openGallery}
          setOpenGallery={setOpenGallery}
        />
      )}

      {openSubTaskRequest && (
        <CreateMaintenanceRequest
          trigger={openSubTaskRequest}
          setTrigger={setOpenSubtaskRequest}
          unitId={maintenanceDetails.unitID}
          linkMaintenanceId={maintenanceDetails.ID}
          operation="Subtask"
          getMaintenanceTickets={getMaintenanceDetails}
        />
      )}

      {openNotesForm && (
        <MaintenanceCloseTicket
          trigger={openNotesForm}
          setTrigger={setOpenNotesForm}
          selectedStatus={selectedMaintenanceStatus}
          handleMaintenanceClose={(note) =>
            updateMaintenanceTicketStatus(
              "Work Order",
              "status",
              selectedMaintenanceStatus,
              ticketId,
              note
            )
          }
        />
      )}

      {maintenanceMediaForm.open && (
        <AddMaintenanceMedia
          trigger={maintenanceMediaForm}
          setTrigger={setMaintenanceMediaForm}
          handleMediaUpload={(files, mediaType, notes) =>
            updateMedia(files, mediaType, notes)
          }
        />
      )}

      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div
        className={
          isTurnoverTicket ? "w-[97%] m-auto" : "w-4/5 md:w-[95%] m-auto"
        }
      >
        <section className="h-12 bg-[#3D72BF] flex justify-between items-center text-white mb-12">
          <span
            id="unit-number-unitAddress"
            className="flex items-center gap-2 ml-2"
          >
            <BsFillArrowLeftCircleFill
              id="backToPrevious"
              name="backToPrevious"
              data-cy="backToPrevious"
              title="Back to Previous Page"
              onClick={() => {
                if (isTurnoverTicket) {
                  props.goBack(false);
                } else {
                  navigate(-1);
                }
              }}
              className="w-6 h-6 cursor-pointer"
            />

            {maintenanceDetails.propertyType !== "Single Family"
              ? maintenanceDetails.unitNumber +
                ` : ` +
                maintenanceDetails.propertyName
              : GetCompleteAddress(unitAddress)}
          </span>

          <span
            id="accountUserNameText"
            className="flex items-center gap-2 mr-2"
          >
            {maintenanceDetails.taskCreatedRole || "-"} :{" "}
            {maintenanceDetails.taskCreatedRoleName || "-"}
            {/* <BsThreeDotsVertical className="" /> */}
          </span>
        </section>

        <div className="grid grid-cols-2 gap-[10%] mb-12">
          <section className="flex flex-col justify-start">
            <div
              name="Categoty & Location"
              className="flex justify-start items-center gap-2"
            >
              <IconInCircle
                icon={<FiTool className="w-8 h-8 text-[#FFA46C]" />}
                radius="4rem"
                color="#FFF2E5"
              />
              <span className="flex flex-col">
                <DetailsTile
                  label={isTurnover ? "Request Type:" : "Category:"}
                  data={
                    isTurnover ? (
                      <span className="ml-3 text-blue-52">
                        {maintenanceDetails.requestType}
                      </span>
                    ) : (
                      <InlineObjectStringSelect
                        name="category"
                        id="category"
                        dataCy="category"
                        options={categoryOptions}
                        value={maintenanceDetails.category}
                        onChange={(e) => {
                          handleCategoryChange(e.target.value);
                          //handleMaintenanceDetailsOnChange(e, true);
                        }}
                        className="!w-fit"
                        optionValue="categories"
                        optionName="categories"
                      />
                    )
                  }
                />
                {!isTurnover && (
                  <>
                    <DetailsTile
                      label="Sub-Category:"
                      data={
                        <InlineObjectStringSelect
                          name="subCategory"
                          id="subCategory"
                          dataCy="subCategory"
                          options={subCategoryOptions}
                          value={maintenanceDetails.subCategory}
                          onChange={(e) =>
                            handleMaintenanceDetailsOnChange(e, true)
                          }
                          optionValue="subcategories"
                          optionName="subcategories"
                          //className="!w-fit"
                        />
                      }
                    />
                    <DetailsTile
                      label="Location:"
                      data={
                        <InlineInputField
                          name="areaOfProblem"
                          id="areaOfProblem"
                          value={maintenanceDetails.areaOfProblem}
                          onChange={handleMaintenanceDetailsOnChange}
                        />
                      }
                    />
                  </>
                )}
              </span>
            </div>

            <div name="Description" className="mt-8  w-full">
              <span className="flex justify-between items-center border-b-2 border-[#42AACB] mb-4">
                Description
                {/* <BsThreeDotsVertical style={{ color: "black" }} /> */}
              </span>
              <p>{maintenanceDetails.description}</p>
            </div>

            <div name="Media" className="mt-8">
              <span className="flex justify-between items-center border-b-2 border-[#42AACB]">
                <p id="media-length">
                  Media (
                  {publicImages.length +
                    privateImages.length +
                    publicVideos.length +
                    privateVideos.length || 0}
                  )
                </p>
                <MdDeleteForever
                  //style={{ color: "black" }}
                  id="deleteMedia"
                  name="deleteMedia"
                  data-cy="deleteMedia"
                  className="hover:text-red-10 w-5 h-5 cursor-pointer"
                  onClick={handleMediaDelete}
                />
              </span>
              <div
                name="Media Type"
                className="grid grid-cols-2 justify-center items-center font-semibold text-center w-full mb-2"
              >
                <span
                  id="clickOnPublicMedia"
                  name="clickOnPublicMedia"
                  data-cy="clickOnPublicMedia"
                  onClick={(e) => setPublicMedia(true)}
                  className={`cursor-pointer flex justify-center items-center h-7 rounded-bl-md ${
                    publicMedia
                      ? "bg-blue-54 text-white"
                      : "bg-gray-300 text-gray-500 font-medium"
                  }`}
                >
                  Public
                </span>

                <span
                  id="clickOnPrivateMedia"
                  name="clickOnPrivateMedia"
                  data-cy="clickOnPrivateMedia"
                  onClick={(e) => setPublicMedia(false)}
                  className={`cursor-pointer flex justify-center items-center h-7 rounded-br-md ${
                    !publicMedia
                      ? "bg-blue-54 text-white"
                      : "bg-gray-300 text-gray-500 font-medium"
                  }`}
                >
                  Private
                </span>
              </div>
              <ShowMedia
                images={publicMedia ? publicImages : privateImages}
                videos={publicMedia ? publicVideos : privateVideos}
                handleGallery={handleGallery}
                handleSelectedMedia={handleSelectedMedia}
              />

              <span className="flex justify-center items-center gap-8 mt-6">
                <ButtonCustom
                  btnName="addPhotos"
                  dataCy="addPhotos"
                  id="addPhotos"
                  name={
                    <>
                      <FiPaperclip className="mr-1" /> Attach Photo
                    </>
                  }
                  onClick={(e) =>
                    setMaintenanceMediaForm({ open: true, mediaType: "image" })
                  }
                  className="flex justify-center items-center rounded-md h-10 px-2 text-[#3D72BF] border border-[#3D72BF] font-semibold hover:bg-[#3D72BF] hover:text-white focus:bg-[#3D72BF] focus:text-white"
                />

                <ButtonCustom
                  id="addVideo"
                  btnName="addVideo"
                  dataCy="addVideo"
                  name={
                    <>
                      <AiOutlinePlayCircle className="mr-1" /> Attach Video
                    </>
                  }
                  onClick={(e) =>
                    setMaintenanceMediaForm({ open: true, mediaType: "video" })
                  }
                  className="flex justify-center items-center rounded-md h-10 px-2 text-[#3D72BF] border border-[#3D72BF] font-semibold hover:bg-[#3D72BF] hover:text-white focus:bg-[#3D72BF] focus:text-white"
                />

                {isTurnover && (
                  <ButtonCustom
                    id="addNewTurnOverRequest"
                    btnName="addNewTurnOverRequest"
                    dataCy="addNewTurnOverRequest"
                    name={
                      <>
                        <PiTreeStructureDuotone className="w-5 h-5 mr-2 rotate-45" />
                        Create Subtask
                      </>
                    }
                    onClick={() => {
                      setOpenSubtaskRequest(true);
                    }}
                    className={`flex justify-center items-center px-2 h-10 rounded-md text-orange-10 border-orange-10 hover:text-white hover:bg-orange-10`}
                  />
                )}
              </span>
            </div>

            {allSubtasks.length ? (
              <div name="Subtasks" className="mt-8">
                <SubtasksWidget
                  borderClassName="border-[#42AACB]"
                  subtasks={allSubtasks}
                  statusOptions={maintenanceStatues}
                  updateSubtaskStatus={updateMaintenanceTicketStatus}
                  deleteSubtask={handleDeleteSubtask}
                  updateMaintenanceDetails={getMaintenanceDetails}
                />
              </div>
            ) : null}

            <div name="Comments" className="mt-8">
              <CommentsWidget
                borderClassName="border-[#42AACB]"
                comments={allComments}
                handleSend={addComment}
                handleDelete={deleteComment}
              />
            </div>
          </section>

          <section
            name="Details"
            className="border box-border mt-12 h-fit px-6 overflow-hidden"
          >
            <span className="flex justify-between items-center border-b-2 my-4 pb-2">
              <p className="font-semibold">Work Order Details</p>
              <label>
                Status:
                <select
                  name="status"
                  id="status"
                  data-cy="status"
                  value={maintenanceDetails.status}
                  onChange={(e) => {
                    setOpenNotesForm(true);
                    setSelectedMaintenanceStatus(e.target.value);
                  }}
                  //style={{ backgroundColor: maintenanceDetails.statusColor }}
                  className={
                    " text-sm py-2 px-4 rounded text-white font-semibold bg-blue-53 w-fit ml-2 cursor-pointer"
                  }
                >
                  {maintenanceStatues.length > 0 ? (
                    <>
                      {maintenanceStatues.map((option) => (
                        <option
                          key={option.status}
                          value={option.status}
                          id={option.status}
                          data-cy={option.status}
                        >
                          {option.status}
                        </option>
                      ))}
                    </>
                  ) : (
                    <option>No Data</option>
                  )}
                </select>
              </label>
            </span>

            <DetailsTile
              label="Work Order ID"
              id={"workOrderId"}
              data={maintenanceDetails.woid}
              className="mb-4"
            />

            <DetailsTile
              label="Created By"
              id="createdBy"
              data={
                <>
                  <IconInCircle
                    id="createdByIcon"
                    icon={
                      maintenanceDetails &&
                      maintenanceDetails.taskCreatedRole.toUpperCase().charAt(0)
                    }
                    radius="1.5rem"
                    color="#3D72BF"
                    className="text-white text-lg"
                  />
                  {maintenanceDetails && (
                    <>
                      {maintenanceDetails.taskCreatedRole === "Client Admin" ? (
                        <> CA : {maintenanceDetails.taskCreatedRoleName}</>
                      ) : maintenanceDetails.taskCreatedRole ===
                        "Property Manager" ? (
                        <> PM : {maintenanceDetails.taskCreatedRoleName}</>
                      ) : (
                        <> T : {maintenanceDetails.taskCreatedRoleName}</>
                      )}
                    </>
                  )}
                </>
              }
              className="mb-4"
              dataClassName="flex whitespace-nowrap"
            />

            <DetailsTile
              label="Assigned To"
              id="assignedTo"
              data={
                <>
                  <IconInCircle
                    icon={
                      maintenanceDetails &&
                      maintenanceDetails.taskAssignedRole
                        .toUpperCase()
                        .charAt(0)
                    }
                    title={
                      maintenanceDetails && maintenanceDetails.taskAssignedRole
                    }
                    radius="1.5rem"
                    color="#0A875C"
                    className="text-white text-lg"
                  />
                  <p
                    title={
                      maintenanceDetails && maintenanceDetails.taskAssignedRole
                    }
                  >
                    {maintenanceDetails &&
                      maintenanceDetails.taskAssignedRole !== "" && (
                        <>
                          {maintenanceDetails.taskAssignedRole ===
                          "Client Admin" ? (
                            <> CA : </>
                          ) : (
                            <> PM : </>
                          )}
                        </>
                      )}
                  </p>

                  <InlineAssignToSelect
                    name="taskAssigned"
                    id="taskAssigned"
                    dataCy="taskAssigned"
                    options={assignToOptions}
                    value={maintenanceDetails.taskAssignedRoleID}
                    optionValue="roleId"
                    optionName="name"
                    onChange={handleTaskAssignedOnChange}
                    className="overflow-ellipsis md:!w-24"
                  />
                </>
              }
              className="mb-4"
              dataClassName="flex items-center whitespace-nowrap"
            />
            <DetailsTile
              label="Priority"
              data={
                <InlineSelect
                  name="priority"
                  id="priority"
                  dataCy="priority"
                  options={priorityOptions}
                  value={maintenanceDetails.priority}
                  onChange={handleMaintenanceDetailsOnChange}
                  className=""
                />
              }
              className="mb-4"
            />

            {isTurnover ? (
              <>
                <DetailsTile
                  label="Turnover Start Date"
                  data={maintenanceDetails.turnoverStartDate}
                  id="turnOverStartDate"
                  className="mb-4"
                />
                <DetailsTile
                  label="Turnover End Date"
                  id="turnOverEndDate"
                  data={maintenanceDetails.turnoverEndDate}
                  className="mb-4"
                />
              </>
            ) : (
              <>
                <DetailsTile
                  label="Availability"
                  id="availability"
                  className="mb-4 !items-start"
                  data={
                    <div className="flex flex-col w-11/12 gap-2">
                      <span className="border-b-2 border-gray-300 text-[#42AACB] mb-2">
                        Slot 1
                      </span>
                      <InlineDateInputField
                        name="availabilityDate1"
                        id="availabilityDate1"
                        value={maintenanceDetails.availabilityDate1}
                        onChange={handleMaintenanceDetailsOnChange}
                      />
                      <InlineTimeRangeField
                        name="availabilityDuration1"
                        id="availabilityDuration1"
                        value={maintenanceDetails.availabilityDuration1}
                        onChange={handleMaintenanceDetailsOnChange}
                      />
                      <span className="border-b-2 border-gray-300 text-[#42AACB] mb-2 mt-2">
                        Slot 2
                      </span>
                      <InlineDateInputField
                        name="availabilityDate2"
                        id="availabilityDate2"
                        value={maintenanceDetails.availabilityDate2}
                        onChange={handleMaintenanceDetailsOnChange}
                      />
                      <InlineTimeRangeField
                        name="availabilityDuration2"
                        id="availabilityDuration2"
                        value={maintenanceDetails.availabilityDuration2}
                        onChange={handleMaintenanceDetailsOnChange}
                      />
                      {maintenanceDetails.availabilityDate3 ? (
                        <>
                          <span className="border-b-2 border-gray-300 text-[#42AACB] mb-2 mt-2">
                            Slot 3
                          </span>
                          <InlineDateInputField
                            name="availabilityDate3"
                            id="availabilityDate3"
                            value={maintenanceDetails.availabilityDate3}
                            onChange={handleMaintenanceDetailsOnChange}
                          />
                          <InlineTimeRangeField
                            name="availabilityDuration3"
                            id="availabilityDuration3"
                            value={maintenanceDetails.availabilityDuration3}
                            onChange={handleMaintenanceDetailsOnChange}
                          />
                        </>
                      ) : null}
                    </div>
                  }
                />
                <DetailsTile
                  label="Vendor"
                  id="vendor"
                  data={
                    <InlineAssignToSelect
                      name="vendorID"
                      id="vendorID"
                      options={vendorOptions}
                      optionValue="roleId"
                      optionName="name"
                      value={maintenanceDetails.vendorID}
                      onChange={handleVendorOnChange}
                      className="overflow-ellipsis md:!w-36 !w-fit"
                    />
                  }
                  className="mb-4 md:w-auto"
                />
              </>
            )}

            <DetailsTile
              label="Created On"
              id="createdOnValue"
              valueID="created-on-value"
              data={GetTimeYearToMin(maintenanceDetails.CreatedAt)}
              className="mb-4"
            />
            <DetailsTile
              label="Last Updated"
              id="lastUpdated"
              valueID="last-updated-value"
              data={GetTimeYearToMin(maintenanceDetails.UpdatedAt)}
              className="mb-12"
            />
          </section>
        </div>
      </div>
    </>
  );
};

export default MaintenanceRequestDetails;

const InlineSelect = (props) => {
  return (
    <>
      <select
        name={props.name}
        id={props.id}
        data-cy={props.dataCy}
        value={props.value}
        onChange={props.onChange}
        className={
          props.className +
          " text-sm bg-transparent p-2 hover:bg-slate-100 rounded"
        }
      >
        {props.options.length > 0 ? (
          <>
            <option disabled value={"" || 0}>
              {props.placeholder || "Select an option"}
            </option>
            {props.options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </>
        ) : (
          <option>No options</option>
        )}
      </select>
    </>
  );
};

const InlineObjectStringSelect = (props) => {
  return (
    <>
      <select
        name={props.name}
        id={props.id}
        data-cy={props.dataCy}
        value={props.value}
        onChange={props.onChange}
        className={
          props.className +
          " text-sm bg-transparent p-2 hover:bg-slate-100 rounded"
        }
      >
        {props.options.length > 0 ? (
          <>
            <option disabled value={""}>
              {props.placeholder || "Select an option"}
            </option>
            {props.options.map((option, index) => (
              <option
                key={index}
                value={option[props.optionValue]}
                id={option[props.optionValue]}
                data-cy={index}
              >
                {option[props.optionName]}
              </option>
            ))}
          </>
        ) : (
          <option>No options</option>
        )}
      </select>
    </>
  );
};

const InlineAssignToSelect = (props) => {
  return (
    <>
      <select
        name={props.name}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        className={
          props.className +
          " text-sm bg-transparent p-2 hover:bg-slate-100 rounded md:p-1"
        }
      >
        {props.options.length > 0 ? (
          <>
            <option disabled value={"" || 0}>
              {props.placeholder || "Select an option"}
            </option>
            {props.options.map((option, index) => (
              <option key={index} value={option[props.optionValue]}>
                {option[props.optionName]}
              </option>
            ))}
          </>
        ) : (
          <option>No options</option>
        )}
      </select>
    </>
  );
};

const InlineInputField = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState("");

  const handleConfirm = (event) => {
    event.preventDefault();
    if (
      event.target.value.trim() !== "" &&
      event.target.value !== props.value
    ) {
      props.onChange(event);
    }
    handleCloseEdit(event);
  };

  const handleCloseEdit = (event) => {
    event.preventDefault();
    setEditMode(false);
    setEditValue("");
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      handleCloseEdit(event);
      handleConfirm(event);
    } else if (event.key === "Escape") {
      handleCloseEdit(event);
    }
  };

  const justView = () => {
    return (
      <>
        <span className="flex items-center gap-2">
          <p
            id={props.value}
            name={props.value}
            data-cy={props.value}
            title="Double Click to Edit"
            onDoubleClick={(e) => setEditMode(true)}
            className="p-2 w-fit rounded-lg hover:bg-slate-100 cursor-pointer"
          >
            {props.value || "N/A"}
          </p>
          <BiPencil
            id="click-on-edit"
            name="click-on-edit"
            data-cy="click-on-edit"
            title="Click to Edit"
            onClick={(e) => setEditMode(true)}
            className={"w-8 h-5 cursor-pointer"}
          />
        </span>
      </>
    );
  };

  const letsEdit = () => {
    return (
      <>
        <span className={"flex items-center justify-end"}>
          <input
            className={
              props.className +
              ` appearance-none rounded p-2 text-gray-700 leading-tight focus:outline-none border pr-16 w-full`
            }
            name={props.name}
            id={props.id}
            type={props.type || "text"}
            min={props.min}
            defaultValue={props.value}
            onChange={(event) => setEditValue(event.target.value)}
            onKeyDown={onKeyDown}
          />
          <span className={`flex absolute gap-2 cursor-pointer mr-1`}>
            <AiOutlineCloseSquare
              onClick={handleCloseEdit}
              className="text-red-10 w-6 h-6"
              id="cancelEdit"
            />
            <AiOutlineCheckSquare
              onClick={(event) => {
                event.target.name = props.name;
                event.target.value = editValue;
                handleConfirm(event);
              }}
              className="text-green-400 w-6 h-6"
              id="submit-edit"
            />
          </span>
        </span>
      </>
    );
  };

  return editMode ? letsEdit() : justView();
};

const InlineDateInputField = (props) => {
  let newDate = new Date();
  const [date, setDate] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState("");

  const handleConfirm = (event) => {
    event.preventDefault();
    if (event.target.value !== props.value) {
      props.onChange(event);
    }
    handleCloseEdit(event);
  };

  const handleCloseEdit = (event) => {
    event.preventDefault();
    setEditMode(false);
    setEditValue("");
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.name = props.name;
      event.target.value = editValue;
      handleConfirm(event);
      handleCloseEdit(event);
    } else if (event.key === "Escape") {
      handleCloseEdit(event);
    }
  };

  const onEditMode = (e) => {
    e.preventDefault();
    setDate(dayjs(props.value));
    setEditValue(props.value);
    setEditMode(true);
  };

  const justView = () => {
    return (
      <>
        <span className="flex items-center gap-2">
          <p
            title="Double Click to Edit"
            id="double-click-to-edit"
            name="double-click-to-edit"
            data-cy="double-click-to-edit"
            onDoubleClick={onEditMode}
            className="p-2 w-fit rounded-lg hover:bg-slate-100 cursor-pointer"
          >
            {props.value || "N/A"}
          </p>
          <BiPencil
            id="edit-datefeild"
            name="edit-datefeild"
            data-cy="edit-datefeild"
            title="Click to Edit"
            onClick={onEditMode}
            className={"w-8 h-5 cursor-pointer"}
          />
        </span>
      </>
    );
  };

  const letsEdit = () => {
    return (
      <>
        <div className="flex flex-col">
          <span onKeyDown={onKeyDown} className={"flex items-center w-full"}>
            {/* <input
            className={
              props.className +
              ` appearance-none rounded p-2 text-gray-700 leading-tight focus:outline-none border pr-16 w-full`
            }
            name={props.name}
            id={props.id}
            type={props.type || "text"}
            min={props.min}
            defaultValue={props.value}
            onChange={(event) => setEditValue(event.target.value)}
            onKeyDown={onKeyDown}
          /> */}

            <DatePicker
              value={date}
              //inputFormat="MM DD YYYY"
              name={props.name}
              id={props.id}
              minDate={dayjs(newDate)}
              onChange={(e) => {
                setEditValue(dayjs(e.$d).format("MM/DD/YYYY"));
                setDate(e);
              }}
              className={
                props.fromClassName +
                ` shadow rounded appearance-none p-2 text-gray-700 leading-tight focus:outline-none w-full`
              }
            />
          </span>
          <span
            className={`flex justify-end items-center gap-2 mt-[2px] cursor-pointer`}
          >
            <AiOutlineCloseSquare
              id="cancel-edit"
              name="cancel-edit"
              data-cy="cancel-edit"
              onClick={handleCloseEdit}
              className="text-red-10 w-6 h-6"
            />
            <AiOutlineCheckSquare
              onClick={(event) => {
                event.target.name = props.name;
                event.target.value = editValue;
                handleConfirm(event);
              }}
              className="text-green-400 w-6 h-6"
              id="submit-edit"
              name="submit-edit"
              data-cy="submit-edit"
            />
          </span>
        </div>
      </>
    );
  };

  return editMode ? letsEdit() : justView();
};

const InlineTimeRangeField = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState("");
  const [timeSlot, setTimeSlot] = useState({
    from: "",
    to: "",
  });
  const fromInitialValue = new Date();
  const toInitialValue = new Date();
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const onEdit = (e) => {
    e.preventDefault();
    let rangeSplit = props.value.split(" - ");
    setTimeSlot({
      from: rangeSplit[0] || "",
      to: rangeSplit[1] || "",
    });
    let fromSplit = rangeSplit[0].split(/[:\s]+/);
    let toSplit = rangeSplit[1].split(/[:\s]+/);

    let fromTime = {
      hours: parseInt(fromSplit[0]),
      min: parseInt(fromSplit[1]),
    };
    let toTime = {
      hours: parseInt(toSplit[0]),
      min: parseInt(toSplit[1]),
    };
    if (fromSplit[2] === "PM" && fromTime.hours !== 12) {
      fromTime.hours = parseInt(fromSplit[0]) + 12;
    }
    fromInitialValue.setHours(fromTime.hours);
    fromInitialValue.setMinutes(fromTime.min);
    if (toSplit[2] === "PM" && toTime.hours !== "12") {
      toTime.hours = parseInt(toSplit[0]) + 12;
    }
    toInitialValue.setHours(toTime.hours);
    toInitialValue.setMinutes(toTime.min);
    setFrom(dayjs(fromInitialValue));
    setTo(dayjs(toInitialValue));
    setEditMode(true);
  };

  const validate = () => {
    let temp = "";
    if (!timeSlot.from || !timeSlot.to) {
      temp = "Available Time is required!";
    } else if (TimeRangeValidation(timeSlot, 120)) {
      temp = "A Valid 2hours time slot is required!";
    }
    return temp;
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    const temp = validate();
    setError(temp);
    if (temp) {
      return;
    } else {
      event.target.name = props.name;
      event.target.value = timeSlot.from + " - " + timeSlot.to;

      if (event.target.value !== props.value) {
        props.onChange(event);
      }
    }

    handleCloseEdit(event);
  };

  const handleCloseEdit = (event) => {
    event.preventDefault();
    setEditMode(false);
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      handleConfirm(event);
    } else if (event.key === "Escape") {
      handleCloseEdit(event);
    }
  };

  const justView = () => {
    return (
      <>
        <span className="flex items-center gap-2">
          <p
            title="Double Click to Edit"
            onDoubleClick={(e) => onEdit(e)}
            className="p-2 w-fit rounded-lg hover:bg-slate-100 cursor-pointer"
          >
            {props.value || "N/A"}
          </p>
          <BiPencil
            title="Click to Edit"
            onClick={(e) => onEdit(e)}
            className={"w-8 h-5 cursor-pointer"}
          />
        </span>
      </>
    );
  };

  const letsEdit = () => {
    return (
      <>
        <div className="flex flex-col">
          <span
            className={"flex items-center gap-1 mt-1 w-full"}
            onKeyDown={onKeyDown}
          >
            <MobileTimePicker
              label="From"
              renderInput={(params) => <TextField {...params} />}
              value={from}
              id={"from" + props.id}
              onChange={(e) => {
                if (e !== null) {
                  setTimeSlot({
                    ...timeSlot,
                    from: GetTimein12HrFormat(e.$d),
                  });
                  setFrom(e);
                }
              }}
              className={
                props.fromClassName +
                ` shadow rounded appearance-none p-2 text-gray-700 leading-tight focus:outline-none`
              }
            />
            <MobileTimePicker
              label="To"
              renderInput={(params) => <TextField {...params} />}
              value={to}
              id={"from" + props.id}
              onChange={(e) => {
                if (e !== null) {
                  setTimeSlot({ ...timeSlot, to: GetTimein12HrFormat(e.$d) });
                  setTo(e);
                }
              }}
              className={
                props.toClassName +
                ` shadow rounded appearance-none p-2 text-gray-700 leading-tight focus:outline-none`
              }
            />
          </span>
          <span
            className={`flex justify-between items-center gap-2 mt-[2px] cursor-pointer`}
          >
            <p hidden={error ? false : true} className="text-red-10 text-sm">
              {error || ""}
            </p>
            <p
              hidden={error ? true : false}
              className="text-sm text-gray-400/100"
            >
              {props.hintText || "Minimum 2 hours slot is required"}
            </p>
            <span className="flex gap-2 items-center">
              <AiOutlineCloseSquare
                onClick={handleCloseEdit}
                className="text-red-10 w-6 h-6"
              />
              <AiOutlineCheckSquare
                onClick={handleConfirm}
                className="text-green-400 w-6 h-6"
              />
            </span>
          </span>
        </div>
      </>
    );
  };

  return editMode ? letsEdit() : justView();
};

const AddNewMedia = (props) => {
  return (
    <>
      <input
        type="file"
        hidden={true}
        ref={props.refrence}
        accept={props.accept}
        multiple
        onChange={props.onChange}
      />
    </>
  );
};

const ShowMedia = ({ images, videos, handleGallery, handleSelectedMedia }) => {
  return (
    <>
      <div style={{ overflowX: "scroll" }}>
        <section className="flex justify-start items-center h-36 bg-slate-50 rounded-lg overscroll-contain gap-1">
          {images.length || videos.length ? (
            <>
              {images.map((src, index) => (
                <object
                  key={index}
                  className="relative rounded w-auto h-full flex-shrink-0 mr-2"
                >
                  <img
                    src={src.fileObjectKey}
                    onClick={(e) => handleGallery(index)}
                    className="cursor-pointer w-32 h-32 rounded hover:transform hover:scale-105 transition duration-300 ease-in-out"
                  />
                  <input
                    type="checkbox"
                    key={src.mediaFileName}
                    className="absolute right-1 top-4 h-4 w-4"
                    onChange={(e) =>
                      handleSelectedMedia(
                        "images",
                        e.target.checked,
                        src.fileObjectKey
                      )
                    }
                  />
                </object>
              ))}
              {videos.map((src, index) => (
                <object
                  key={index}
                  className="relative rounded w-auto h-full flex-shrink-0 mr-2"
                >
                  <video
                    key={index}
                    className="w-full h-full rounded"
                    onClick={(e) => handleGallery(index)}
                    controls
                  >
                    <source src={src.fileObjectKey} />
                    Your browser does not support HTML video.
                  </video>
                  <input
                    type="checkbox"
                    key={src.mediaFileName}
                    className="absolute right-1 top-4 h-4 w-4"
                    onChange={(e) =>
                      handleSelectedMedia(
                        "videos",
                        e.target.checked,
                        src.fileObjectKey
                      )
                    }
                  />
                </object>
              ))}
            </>
          ) : (
            <p className="ml-[35%]">No data to show</p>
          )}
        </section>
      </div>
    </>
  );
};

//Maintenance Comments Widget
const CommentsWidget = (props) => {
  const [comments, setComments] = useState(props.comments);
  const [newComment, setNewComment] = useState("");
  const [sorting, setSorting] = useState("NewestFirst");
  const [isPublicComment, setIsPublicComment] = useState(true);

  if (comments.length !== props.comments.length) {
    if (sorting === "NewestFirst") {
      setComments(props.comments.slice().reverse());
    } else {
      setComments(props.comments);
    }
  }

  const handleConfirm = (event) => {
    event.preventDefault();
    if (newComment.trim() !== "") {
      props.handleSend(newComment, isPublicComment);
    }
    setNewComment("");
  };

  const handleDelete = (event, ID) => {
    event.preventDefault();
    props.handleDelete(ID);
  };

  const handleKeydDown = (event) => {
    if (event.key === "Enter") {
      handleConfirm(event);
    }
  };

  const handleSorting = (e) => {
    e.preventDefault();
    setSorting(e.target.value);
    if (e.target.value === "OldestFirst") {
      setComments(props.comments);
    } else {
      setComments(props.comments.slice().reverse());
    }
  };

  return (
    <>
      <span
        className={
          props.borderClassName +
          " flex justify-between items-center border-b-2"
        }
      >
        Comments
        <select
          name="CommentsViewSort"
          id="CommentsViewSort"
          value={sorting}
          onChange={handleSorting}
          className={
            props.selectClassName +
            " text-sm bg-transparent p-2 hover:bg-slate-100 rounded"
          }
        >
          <option key="NewestFirst" value="NewestFirst">
            {" "}
            Newest First
          </option>
          <option key="OldestFirst" value="OldestFirst">
            {" "}
            Oldest First
          </option>
        </select>
      </span>

      <div className="flex flex-col items-center justify-center gap-4">
        <div
          name="Comments Type"
          className="grid grid-cols-2 justify-center items-center font-semibold text-center w-full"
        >
          <span
            id="publicType"
            data-cy="publicType"
            onClick={(e) => setIsPublicComment(true)}
            className={`cursor-pointer flex justify-center items-center h-7 rounded-bl-md ${
              isPublicComment
                ? "bg-blue-54 text-white"
                : "bg-gray-300 text-gray-500 font-medium"
            }`}
          >
            Public
          </span>

          <span
            id="privateType"
            data-cy="privateType"
            onClick={(e) => setIsPublicComment(false)}
            className={`cursor-pointer flex justify-center items-center h-7 rounded-br-md ${
              !isPublicComment
                ? "bg-blue-54 text-white"
                : "bg-gray-300 text-gray-500 font-medium"
            }`}
          >
            Private
          </span>
        </div>
        <span className="flex justify-center items-center gap-4 w-full">
          <IconInCircle
            icon={<AiOutlineMessage />}
            radius={props.iconRadius || "2rem"}
            color={isPublicComment ? "#808080" : "#7E3AF2"}
            className={"text-white text-lg saturate-50"}
          />
          <span className="flex justify-end items-center w-[90%]">
            <input
              className={
                props.inputClassName +
                ` appearance-none rounded p-2 text-gray-700 leading-tight focus:outline-none border w-full pr-[35px]`
              }
              name="newComment"
              id="newComment"
              type="text"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              onKeyDown={handleKeydDown}
            />
            <SlPaperPlane
              id="submit-comment"
              name="submit-comment"
              data-cy="submit-comment"
              onClick={handleConfirm}
              className="w-5 h-5 absolute cursor-pointer mr-1"
            />
          </span>
        </span>
        <div
          name="CommentsViewScreen"
          className="overflow-y-auto overscroll-contain max-h-96 w-full"
        >
          {comments.map((comment, index) => (
            <span
              key={index}
              id={index}
              name={index}
              data-cy={index}
              hidden={comment.isPublic !== isPublicComment ? true : false}
              className={
                comment.isPublic !== isPublicComment
                  ? "hidden"
                  : "flex justify-center items-center gap-4 mb-4"
              }
            >
              <IconInCircle
                id={comment.role[0]}
                icon={comment.role[0] || <FaWrench />}
                radius={props.iconRadius || "2rem"}
                color={comment.role === "Tenant" ? "#3661A9" : "#0A875C"}
                className="text-white text-lg"
              />
              <span className="flex flex-col w-[90%] text-base">
                <span className="flex justify-between items-center text-sm">
                  <p
                    id="comment-owner-name"
                    className="font-normal"
                    // hidden={
                    //   comment.isStatusChanged || comment.isDocumentDeleted
                    // }
                  >
                    {comment.role + ": " + comment.name}
                  </p>
                  <p id="commentTime" className="text-[#BDBABA] ml-auto">
                    {GetTimeYearToMin(comment.UpdatedAt)}
                  </p>
                </span>
                <p
                  className="font-light bg-[#E8EAF6] rounded p-2 break-words"
                  style={
                    comment.isStatusChanged || comment.isDocumentDeleted
                      ? { backgroundColor: "#FFF2E5" }
                      : null
                  }
                >
                  {comment.comment}
                  <MdDeleteForever
                    id="delete-comment"
                    name="delete-comment"
                    data-cy="delete-comment"
                    onClick={(e) => handleDelete(e, comment.ID)}
                    className="float-right w-4 h-4 hover:text-red-10 cursor-pointer my-auto"
                  />
                </p>
              </span>
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

//Maintenance Subtasks Widget
const SubtasksWidget = ({ statusOptions, updateSubtaskStatus, ...props }) => {
  const [subtasks, setSubtasks] = useState(props.subtasks);
  const [sorting, setSorting] = useState("NewestFirst");
  const [openSubtask, setOpenSubtask] = useState(false);
  const [subtaskData, setSubtaskData] = useState("");

  useEffect(() => {
    setSubtasks(props.subtasks.slice().reverse());
  }, [props.subtasks]);

  const handleSorting = (e) => {
    e.preventDefault();
    setSorting(e.target.value);
    if (e.target.value === "OldestFirst") {
      setSubtasks(props.subtasks);
    } else {
      setSubtasks(props.subtasks.slice().reverse());
    }
  };

  return (
    <>
      {openSubtask && (
        <ViewSubtask
          trigger={openSubtask}
          setTrigger={setOpenSubtask}
          data={subtaskData}
          updateMaintenanceDetails={props.updateMaintenanceDetails}
        />
      )}

      <span
        className={props.borderClassName + " flex justify-between items-center"}
      >
        Subtasks
        <select
          name="subtasksSort"
          id="subtasksSort"
          value={sorting}
          onChange={handleSorting}
          className={" text-sm bg-transparent p-2 hover:bg-slate-100 rounded"}
        >
          <option key="NewestFirst" value="NewestFirst">
            Newest First
          </option>
          <option key="OldestFirst" value="OldestFirst">
            Oldest First
          </option>
        </select>
      </span>

      <div
        name="SubtasksViewScreen"
        className="flex flex-col justify-center gap-1 overflow-y-auto overscroll-contain max-h-80 w-full"
      >
        {subtasks.map((subtask, index) => (
          <div
            key={index}
            className={`flex items-center justify-between px-1 h-8 gap-2 bg-transparent text-left border rounded border-gray-400 hover:border-blue-50 hover:bg-[#FAFAFA]`}
          >
            <p
              onClick={(name) => {
                setSubtaskData(subtask);
                setOpenSubtask(true);
              }}
              className="font-semibold text-blue-52 cursor-pointer"
            >
              {subtask.woid}
            </p>

            <p
              onClick={(name) => {
                setSubtaskData(subtask);
                setOpenSubtask(true);
              }}
              className="flex-grow truncate text-ellipsis cursor-pointer"
            >
              {subtask.description}
            </p>

            <div className="flex items-center gap-1">
              <select
                name="status"
                id="status"
                value={subtask.status}
                onChange={(e) =>
                  updateSubtaskStatus(
                    "Subtask",
                    "status",
                    e.target.value,
                    subtask.ID,
                    ""
                  )
                }
                style={{
                  backgroundColor: subtask.statusColor,
                }}
                className={
                  "text-sm rounded text-white font-semibold bg-slate-500 p-[2px]"
                }
              >
                {statusOptions.length > 0 ? (
                  <>
                    {statusOptions.map((option) => (
                      <option key={option.status} value={option.status}>
                        {option.status}
                      </option>
                    ))}
                  </>
                ) : (
                  <option>No options</option>
                )}
              </select>

              <MdDeleteOutline
                title="Delete"
                onClick={(name) => props.deleteSubtask(subtask)}
                className="w-5 h-5 cursor-pointer hover:text-red-500 focus:text-red-500"
              />

              {/* <Dropdown
                selector={
                  <BsThreeDotsVertical
                    title="Additional options"
                    className="w-5 h-5 cursor-pointer hover:text-blue-53 focus:text-blue-53"
                  />
                }
                options={[
                  {
                    name: "Details",
                    onClick: (name) => {
                      setSubtaskData(subtask);
                      setOpenSubtask(true);
                    },
                  },
                  {
                    name: "Delete",
                    onClick: (name) => props.deleteSubtask(subtask),
                  },
                ]}
              /> */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

//View Subtask Details
const ViewSubtask = (props) => {
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {}, []);

  return (
    props.trigger && (
      <>
        <Dialog
          open={true}
          aria-labelledby="Turnover task Maintenance Details"
          fullWidth
          maxWidth="lg"
          className="w-full rounded mx-auto"
        >
          <MaintenanceRequestDetails
            turnoverTaskID={props.data.ID}
            goBack={() => {
              props.setTrigger(false);
              props.updateMaintenanceDetails();
            }}
          />
        </Dialog>
      </>
    )
  );
};

//Comment for Closing Maintenance Ticket
const MaintenanceCloseTicket = (props) => {
  const [comment, setComment] = useState("");

  const confirm = (e) => {
    e.preventDefault();
    if (!comment.trim()) {
      swal(
        "Warning",
        `Please Enter the reason for Closing Maintenance !`,
        "warning"
      );
    } else {
      props.handleMaintenanceClose(comment);
      setComment("");
      props.setTrigger(false);
    }
  };

  return (
    <>
      <Dialog
        open={props.trigger}
        aria-labelledby="Closing Maintenance"
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: { height: "auto" } }}
        scroll="body"
        className="mx-auto w-full rounded"
      >
        <span className="flex justify-center items-center mb-6 bg-blue-52 text-white font-semibold text-center rounded-sm h-10">
          Ticket Status
        </span>

        <section className="flex flex-col justify-center items-center">
          <IconInCircle
            icon={<PiWarningOctagon className="w-9 h-9 text-white" />}
            radius="4rem"
            color=""
            className="bg-blue-52 mx-auto"
          />

          <p className="mx-auto">
            Would you like to Change the Status to {props.selectedStatus} ?
          </p>

          <InputTextArea
            //label="Note"
            name="Comment"
            notImp
            id="Comment"
            type="text"
            placeholder="Enter Comment here..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            //errorText={errors.note}
            style="!mt-4 !mb-6"
            className="h-16 rounded-lg"
          />

          <div className="flex gap-4 items-center mb-4">
            <ButtonGrayOutline
              name="Cancel"
              onClick={(e) => {
                setComment("");
                props.setTrigger(false);
              }}
              className="justify-self-end"
            />
            <ButtonBlue
              name={"Confirm"}
              onClick={(e) => confirm(e)}
              className=""
            />
          </div>
        </section>
      </Dialog>
    </>
  );
};

//Comment for Closing Maintenance Ticket
const AddMaintenanceMedia = (props) => {
  const [media, setMedia] = useState([]);
  const [notes, setNotes] = useState("");
  const Accept =
    props.trigger.mediaType === "image"
      ? ".png, .jpg, .jpeg"
      : ".webp, .mp4, .ogg, .webm";

  const validateFileTypes = (files, fileExtensions) => {
    const allowedExtensions = fileExtensions
      .split(",")
      .map((type) => type.trim());

    const validFiles = [];
    const invalidFiles = [];

    for (let i = 0; i < files.length; i++) {
      const fileExtension = files[i].name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes("." + fileExtension)) {
        validFiles.push(files[i]);
      } else {
        invalidFiles.push(files[i]);
      }
    }

    if (invalidFiles.length > 0) {
      swal(
        "Warning",
        `${invalidFiles.length} Invalid file types. "Only " ${fileExtensions} files are allowed.`,
        "warning"
      );
    }

    if (validFiles.length > 0) {
      return validFiles;
    }
  };

  const confirm = (e) => {
    e.preventDefault();
    if (!media) {
      swal(
        "Warning",
        `Please Choose some ${props.mediaType} to Attach !`,
        "warning"
      );
    } else {
      const finalFiles = validateFileTypes(media, Accept);
      if (finalFiles.length) {
        props.handleMediaUpload(finalFiles, props.trigger.mediaType, notes);
        setNotes("");
        setMedia([]);
        props.setTrigger({ open: false, mediaType: "" });
      }
    }
  };

  return (
    <>
      <Dialog
        open={props.trigger.open}
        aria-labelledby="Maintenance Media"
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: { height: "auto" } }}
        scroll="body"
        className="mx-auto w-full rounded"
      >
        <span
          id="attach-media-popup-text"
          className="flex justify-center items-center mb-6 bg-blue-52 text-white font-semibold text-center rounded-sm h-10"
        >
          Attach {props.trigger.mediaType}
        </span>

        <section className="flex flex-col justify-center items-center w-96 mx-auto">
          <InputFileField
            label="Attach Media"
            name="media"
            id="media"
            accept={Accept}
            placeholder="Select Media Files"
            multiple
            maxSizeinMB={10}
            value={media}
            setValue={setMedia}
            style="!w-full"
            hintText="Each Media size should be less than 10MB"
          />

          <InputTextArea
            label="Notes"
            name="notes"
            notImp
            id="notes"
            type="text"
            placeholder="Enter notes here..."
            value={notes}
            onChange={(e) => setNotes(e.target.value.replace(/[^\w\s]/gi, ""))}
            style="!mt-4 !mb-6 !w-full"
            className="h-16 rounded-lg"
          />

          <div className="flex gap-4 items-center mb-4">
            <ButtonGrayOutline
              id="cancel"
              dataCy="cancel"
              name="Cancel"
              onClick={(e) => {
                setNotes("");
                setMedia([]);
                props.setTrigger({ open: false, mediaType: "" });
              }}
              className="justify-self-end"
            />
            <ButtonBlue
              id="attach"
              dataCy="attach"
              name={"Attach"}
              onClick={(e) => confirm(e)}
              className=""
            />
          </div>
        </section>
      </Dialog>
    </>
  );
};

//Gallery for Maintenance Media
const MaintenanceMediaGallery = ({
  images,
  videos,
  index,
  openGallery,
  setOpenGallery,
}) => {
  const [slideNumber, setSlideNumber] = useState(index || 0);
  const hasImages = Array.isArray(images) && images.length > 0;
  const hasVideos = Array.isArray(videos) && videos.length > 0;
  const galleryItems = [
    ...(hasImages ? images.filter(Boolean) : []),
    ...(hasVideos ? videos.filter(Boolean) : []),
  ];

  // Close Modal
  const handleCloseGallery = () => {
    setOpenGallery(false);
  };

  // Previous Slide
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(galleryItems.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  // Next Slide
  const nextSlide = () => {
    slideNumber + 1 === galleryItems.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  const currentItem = galleryItems[slideNumber];
  const isVideo = hasVideos && videos.includes(currentItem);

  return (
    openGallery && (
      <div className="gallery">
        <span className="index text-2xl">
          {slideNumber + 1}/{galleryItems.length}
        </span>
        <AiFillCloseCircle
          className="btnClose w-8 h-8"
          onClick={handleCloseGallery}
        />
        <BsArrowLeftCircleFill
          className="btnPrev w-8 h-8"
          onClick={prevSlide}
        />
        <BsArrowRightCircleFill
          className="btnNext w-8 h-8"
          onClick={nextSlide}
        />
        <div className="fullScreenItem">
          {isVideo ? (
            <video src={currentItem.fileObjectKey} controls />
          ) : (
            <img
              src={currentItem.fileObjectKey}
              alt={currentItem.mediaFileName}
            />
          )}
          <div className="notes">{currentItem.notes}</div>
        </div>
      </div>
    )
  );
};
