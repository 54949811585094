import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../WebApiManager";
import { userData, companyId } from "../../atoms";
import { useRecoilValue } from "recoil";
import { IconInCircle } from "../../Widgets/Icons";
import InputField, {
  InputTextArea,
  InputSelectField,
  InputMultiSelectField,
  InputDateField,
  InputFileField,
  InputDateAndTimeRangeField,
  InputObjectSelectField,
} from "../../Widgets/Forms/InputFields";
import { TimeRangeValidation } from "../../Widgets/Forms/FieldDependants";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIcon,
} from "../../Widgets/Buttons";
import { AiOutlineCloseCircle } from "react-icons/ai";

import swal from "sweetalert";
import { Loader, SnackAlert } from "../../Widgets/notificationFeedbacks";
import { Dialog, DialogContent } from "@mui/material";
import { FiTool } from "react-icons/fi";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

const CreateMaintenanceRequest = (props) => {
  let webApi = new WebApimanager();
  const userInfo = useRecoilValue(userData);
  const companyID = useRecoilValue(companyId);
  const unitId = props.unitId;
  const [allUnits, setAllUnits] = useState([]);
  const [selectedUnitId, setSelectedUnitId] = useState(props.unitId || "");
  const [isTurnover, setIsTurnover] = useState(false);
  const [isSubtask, setIsSubtask] = useState(
    props.operation === "Subtask" ? true : false
  );
  const [formData, setFormData] = useState({
    problem: "",
    subCategory: "",
    location: "",
    priority: "",
    assignTo: {},
    assignToIndex: "",
    vendorId: "",
  });
  const [turnOverFormData, setTurnOverFormData] = useState({
    notes: "",
    turnoverStartDate: "",
    turnoverEndDate: "",
  });
  const [media, setMedia] = useState([]);

  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [availability1, setAvailability1] = useState({
    date: "",
    from: "",
    to: "",
  });
  const [availability2, setAvailability2] = useState({
    date: "",
    from: "",
    to: "",
  });
  const [availability3, setAvailability3] = useState({
    date: "",
    from: "",
    to: "",
  });
  const [addTimeSlot3, setAddTimeSlot3] = useState(false);

  const [subCategoryOptions, setSubCategoryOptions] = useState([]);

  const [formErrors, setFormErrors] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [ticketId, setTicketId] = useState("");
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [assignToOptions, setAssignToOptions] = useState([]);
  const [vendorsOptions, setVendorsOptions] = useState([]);
  const [mediaNotes, setMediaNotes] = useState("");
  const [snackAlert, setSnackAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });

  useEffect(() => {
    getPriorityOptions();
    getAssignToOptions();
    if (!props.unitId) {
      setAllUnits(props.allUnits);
      setSelectedUnitId(
        props.allUnits.length === 1 ? props.allUnits[0].ID : ""
      );
    }
  }, []);

  const validate = () => {
    const errors = {};
    if (activeStep === 0) {
      if (!formData.problem.trim() && !isTurnover) {
        errors.problem = "Problem Description is required!";
      }
      if (!selectedUnitId && allUnits.length > 1) {
        errors.unit = "Unit is required!";
      }
    } else if (activeStep === 1) {
      if (!formData.problem.trim()) {
        errors.problem = "Problem Description is required!";
      }
      if (!formData.priority) {
        errors.priority = "Priority is required!";
      }
      if (!formData.assignTo.name) {
        errors.assignTo = "Assigning is required!";
      }

      if (isTurnover) {
        if (!turnOverFormData.turnoverStartDate) {
          errors.turnoverStartDate = "Start Date is required!";
        }
        if (!turnOverFormData.turnoverEndDate) {
          errors.turnoverEndDate = "End Date is required!";
        }
      }

      if (!isTurnover) {
        if (!category) {
          errors.category = "Category is required!";
        }
        if (!formData.subCategory) {
          errors.subCategory = "Sub-Category is required!";
        }

        if (!availability1.date) {
          errors.availability1 = "Date is required!";
        } else if (!availability1.from || !availability1.to) {
          errors.availability1 = "Available Time Slot is required!";
        } else if (TimeRangeValidation(availability1, 120)) {
          errors.availability1 = "A Valid 2hour time slot is required!";
        }

        if (!availability2.date) {
          errors.availability2 = "Date is required!";
        } else if (!availability2.from || !availability2.to) {
          errors.availability2 = "Available Time Slot is required!";
        } else if (TimeRangeValidation(availability2, 120)) {
          errors.availability2 = "A Valid 2hours time slot is required!";
        } else if (
          //here
          availability1.date === availability2.date &&
          (availability1.from === availability2.from ||
            availability1.to === availability2.to)
        ) {
          errors.availability2 = "Slot 1 and Slot 2 shouldn't overlap!";
        }

        if (addTimeSlot3) {
          if (!availability3.date) {
            errors.availability3 = "Date is required!";
          } else if (!availability3.from || !availability3.to) {
            errors.availability3 = "Available Time Slot is required!";
          } else if (TimeRangeValidation(availability3, 120)) {
            errors.availability3 = "A Valid 2hours time slot is required!";
          } else if (
            availability1.date === availability3.date &&
            (availability1.from === availability3.from ||
              availability1.to === availability3.to)
          ) {
            errors.availability3 = "Slot 1 and Slot 3 shouldn't overlap!";
          } else if (
            availability2.date === availability3.date &&
            (availability2.from === availability3.from ||
              availability2.to === availability3.to)
          ) {
            errors.availability3 = "Slot 2 and Slot 3 shouldn't overlap!";
          }
        }
      }

      if (!isTurnover && !isSubtask) {
        if (!formData.location.trim()) {
          errors.location = "Location Of Problem is required!";
        }
      }
    }
    return errors;
  };

  const handleSubmit = () => {
    if (apiProcessing.loader) {
      setSnackAlert({
        open: true,
        type: "warning",
        message: "Operation in Progress..!",
      });
    } else if (activeStep === 0 && isTurnover) {
      setActiveStep((currentStep) => currentStep + 1);
    } else if (activeStep === 0) {
      setApiProcessing({
        loader: true,
        message: "Gathering Maintenance Categories...",
      });
      let data = {
        description: formData.problem,
      };
      webApi
        .get("/maintenance/categories")
        .then(async (res) => {
          if (res.data.errorCode) {
            swal("Failure", res.data.errorMessage, "error");
          } else {
            if (!priorityOptions.length) {
              getPriorityOptions();
            }
            if (!assignToOptions.length) {
              getAssignToOptions();
            }
            setCategoryOptions(res.data);
            setActiveStep((currentStep) => currentStep + 1);
          }
          setApiProcessing({ loader: false, message: `` });
        })
        .catch((error) => {
          setApiProcessing({ loader: false, message: `` });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to Load Categories, Please try again later.`,
              "error"
            );
          }
        });
    } else if (activeStep === 1) {
      setApiProcessing({
        loader: true,
        message: isTurnover
          ? "Creating Turnover..."
          : isSubtask
          ? "Creating Subtask..."
          : "Creating Maintenance WorkOrder",
      });

      let MediaData = [];
      for (var i = 0; i < media.length; i++) {
        MediaData.push({
          mediaFileName: media[i].name,
          uploadedByID: userInfo.businessAddress.ClientId,
          userType: userInfo.userRole,
          notes: mediaNotes,
          folder: "Public",
        });
      }

      let data = {
        unitID: selectedUnitId,
        taskCreatedRole: userInfo.userRole,
        taskCreatedRoleID: userInfo.businessAddress.ClientId,
        taskCreatedRoleName: userInfo.firstName,

        priority: formData.priority,
        description: formData.problem,
        taskAssignedRole: formData.assignTo.role,
        taskAssignedRoleID: formData.assignTo.roleId,
        taskAssigned: formData.assignTo.name,

        //Tweak for Maintenance Technician asked by @Rahul
        assignedToRole: userInfo.userRole,
        assignedToRoleID: userInfo.businessAddress.ClientId,
      };

      if (isTurnover) {
        data.requestType = "Turnover";
        data.note = turnOverFormData.notes;
        data.turnoverStartDate = turnOverFormData.turnoverStartDate;
        data.turnoverEndDate = turnOverFormData.turnoverEndDate;
      }

      if (isSubtask) {
        data.requestType = "Subtask";
        data.linkedMaintenanceId = props.linkMaintenanceId;
      }

      if (!isTurnover) {
        data.category = category;
        data.subCategory = formData.subCategory;
        data.vendorID = formData.vendorId;

        data.availabilityDate1 = availability1.date;
        data.availabilityDuration1 =
          availability1.from + " - " + availability1.to;
        data.availabilityDate2 = availability2.date;
        data.availabilityDuration2 =
          availability2.from + " - " + availability2.to;
        data.availabilityDate3 = availability3.date;
        data.availabilityDuration3 =
          availability3.date && availability3.from && availability3.to
            ? availability3.from + " - " + availability3.to
            : "";
      }

      if (!isTurnover && !isSubtask) {
        data.areaOfProblem = formData.location;
        data.media = MediaData;
      }

      webApi
        .post("/maintenance/tickets", data)
        .then(async (res) => {
          if (res.data.errorCode) {
            swal("Failure", res.data.errorMessage, "error");
            setApiProcessing({
              loader: false,
              message: "Loading...",
            });
          } else {
            setTicketId(res.data.woid);
            if (res.data.media.length) {
              setApiProcessing({
                loader: true,
                message: "Uploading Media...",
              });

              const uploadMedia = handleMediaUpload(res.data.media, media);

              Promise.all([...uploadMedia])
                .then((results) => {
                  handleMaintenanceCallback(res.data);
                })
                .catch((error) => {
                  handleMaintenanceCallback(res.data);
                });
            } else {
              handleMaintenanceCallback(res.data);
            }
          }
        })
        .catch((error) => {
          setApiProcessing({ loader: false, message: `` });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to Create a Maintenance Work Order, Please try again later.`,
              "error"
            );
          }
        });
    }
  };

  const handleMaintenanceCallback = (maintenanceData) => {
    setApiProcessing({ loader: false, message: `` });
    props.getMaintenanceTickets(maintenanceData);
    setActiveStep((currentStep) => currentStep + 1);
  };

  const handleMediaUpload = (response, data, dataType) => {
    let failedToUploadCount = 0;
    const uploadPromises = data.map((file, i) => {
      setSnackAlert({
        open: true,
        type: "info",
        message:
          "Uploading " + (i + 1) + "/" + data.length + " of Selected Files",
      });

      const metaData = {
        filename: file.name,
        mediatype: file.type,
      };
      return webApi
        .uploadFile(response[i].fileObjectKey, metaData, file)
        .then((res) => {})
        .catch((error) => {
          failedToUploadCount++;
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Failed to upload ${file.name}. Please try again later.`,
              "error"
            );
          }
        });
    });

    return uploadPromises;
  };

  const handleSteps = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      handleSubmit();
    }
  };

  const getPriorityOptions = () => {
    webApi
      .get("/maintenance/tickets/list/priority")
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setPriorityOptions(res.data.priority);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to load Priority Options, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getAssignToOptions = () => {
    webApi
      .get(`/maintenance/tickets/task/assignee/${companyID}`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setAssignToOptions(res.data.others);
          setVendorsOptions(res.data.vendors);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to load Assign To, Vendor Options, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleBack = (event) => {
    event.preventDefault();
    setActiveStep((currentStep) => currentStep - 1);
  };

  const handleRemoveAvailablity3 = (e) => {
    e.preventDefault();
    console.log("inside remove availability");
    setAvailability3({
      date: "",
      from: "",
      to: "",
    });
    setAddTimeSlot3(false);
  };

  // Handle category selection change
  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    const selectedCategoryObject = categoryOptions.find(
      (category) => category.categories === selectedCategory
    );
    if (selectedCategoryObject) {
      getSubCategories(selectedCategoryObject.id);
    }
    setCategory(selectedCategory);
  };

  //Fetch SubCategories
  const getSubCategories = (categoryId) => {
    setApiProcessing({ loader: true, message: `Gathering Sub-Categories` });
    webApi
      .get(`/maintenance/subcategories/${categoryId}`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setSubCategoryOptions(res.data);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to load Sub-Categories, Please try again later.`,
            "error"
          );
        }
      });
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Create Maintenance Form"
        fullWidth
        maxWidth="md"
        PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="w-full rounded mx-auto"
      >
        <SnackAlert
          open={snackAlert.open}
          type={snackAlert.type}
          message={snackAlert.message}
          handleSnackbar={snackAlert}
          handleClose={() => setSnackAlert({ ...snackAlert, open: false })}
        />

        <Loader open={apiProcessing.loader} message={apiProcessing.message} />

        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span id="maintenancePageTitle" className="flex-grow text-center">
            {isTurnover && "Add Turnover Request"}{" "}
            {isSubtask && "Add Sub-Task Request"}
            {!isTurnover && !isSubtask && "Add Maintenance Request"}
          </span>
          <ButtonIcon
            id="close"
            btnName="close"
            dataCy="close"
            title="Close"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <DialogContent dividers>
          <form className="flex flex-col items-center justify-start w-11/12 m-auto">
            {activeStep === 0 ? (
              <>
                <IconInCircle
                  id="maintenanceIcon"
                  icon={<FiTool className="w-9 h-9 text-white" />}
                  radius="4rem"
                  color="#FFAF09"
                />
                <p id="helperText1" className="font-semibold text-lg mb-1">
                  Before You Begin!
                </p>
                <p
                  id="helperText2"
                  className="text-center text-gray-400/100 mb-8 leading-5"
                >
                  Be Specifc about your Problems and <br /> Read all
                  instructions carefully before opening a Request!
                </p>

                {allUnits.length > 1 ? (
                  <InputObjectSelectField
                    label="Select Unit"
                    name="unit"
                    id="selectUnit"
                    dataCy="unit"
                    placeholder="Choose a Unit"
                    value={selectedUnitId}
                    onChange={(e) =>
                      setSelectedUnitId(parseInt(e.target.value))
                    }
                    options={allUnits}
                    optionValue="ID"
                    optionName="unitNumber"
                    style="w-96 mb-6"
                    errorText={formErrors.unit}
                  />
                ) : null}

                {!isSubtask && (
                  <InputObjectSelectField
                    label="Request Type"
                    name="requestType"
                    id="requestType"
                    dataCy="requestType"
                    placeholder="Select Request Type"
                    value={isTurnover.toString()}
                    onChange={(e) => {
                      // if (JSON.parse(e.target.value)) {
                      //     setActiveStep(1);
                      // }
                      setIsTurnover(JSON.parse(e.target.value));
                    }}
                    options={[
                      { name: "Maintenance Request", value: "false" },
                      { name: "Turnover Request", value: "true" },
                    ]}
                    optionValue="value"
                    optionName="name"
                    style="w-96 mb-6"
                    errorText={""}
                  />
                )}

                {!isTurnover && (
                  <InputTextArea
                    label="Description of Problem"
                    name="problemDescription"
                    id="problemDescription"
                    dataCy="problemDescription"
                    type="text"
                    placeholder="Enter Description here..."
                    value={formData.problem}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        problem: e.target.value.replace(/[^\w\s]/gi, ""),
                      })
                    }
                    errorText={formErrors.problem}
                    style="!w-96"
                    className="h-20 rounded-lg"
                  />
                )}

                <div className="flex gap-16 items-center mt-12 mb-8">
                  <ButtonGrayOutline
                    id="cancel"
                    btnName="cancel"
                    dataCy="cancel"
                    name="Cancel"
                    onClick={(e) => props.setTrigger(false)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    id="next"
                    btnName="next"
                    dataCy="next"
                    name="Next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </div>
              </>
            ) : null}

            {activeStep === 1 ? (
              <>
                <div className="grid grid-cols-2 gap-x-16 gap-y-6 items-center justify-center m-auto">
                  <span
                    id="requestDetailsHeader"
                    className="border-b-2 border-blue-52 pb-1 text-blue-52 w-full col-span-2"
                  >
                    {isTurnover || isSubtask
                      ? "Submit Request"
                      : "Problem Details"}
                  </span>

                  <InputTextArea
                    label="Description of Problem"
                    name="problemDescription"
                    id="problemDescription"
                    dataCy="problemDescription"
                    type="text"
                    placeholder="Enter Description here..."
                    value={formData.problem}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        problem: e.target.value.replace(/[^\w\s]/gi, ""),
                      })
                    }
                    errorText={formErrors.problem}
                    style="!w-full"
                    className="h-16 rounded-lg"
                  />

                  {!isTurnover && (
                    // <InputMultiSelectField
                    //   label="Category"
                    //   name="category"
                    //   id="category"
                    //   placeholder="Select a Category"
                    //   options={categoryOptions}
                    //   value={category}
                    //   setValue={setCategory}
                    //   errorText={formErrors.category}
                    //   style="!w-full"
                    // />

                    <InputObjectSelectField
                      label="Category"
                      name="Category"
                      id="category"
                      dataCy="category"
                      placeholder="Select a Category"
                      value={category}
                      onChange={handleCategoryChange}
                      options={categoryOptions}
                      optionValue="categories"
                      optionName="categories"
                      errorText={formErrors.category}
                      style="!w-full"
                    />
                  )}

                  {isTurnover && (
                    <InputTextArea
                      label="Notes"
                      name="Notes"
                      id="Notes"
                      dataCy="notes"
                      type="text"
                      notImp
                      placeholder="Enter Notes here..."
                      value={turnOverFormData.notes}
                      onChange={(e) =>
                        setTurnOverFormData({
                          ...turnOverFormData,
                          notes: e.target.value,
                        })
                      }
                      errorText={""}
                      style="!w-full"
                      className="h-20 rounded-lg"
                    />
                  )}

                  {!isTurnover && (
                    <InputObjectSelectField
                      label="Sub-Category"
                      name="SubCategory"
                      id="SubCategory"
                      dataCy="subCategory"
                      placeholder="Select a Sub-Category"
                      options={subCategoryOptions}
                      value={formData.subCategory}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          subCategory: e.target.value,
                        })
                      }
                      optionValue="subcategories"
                      optionName="subcategories"
                      errorText={formErrors.subCategory}
                      style="!w-full"
                    />
                  )}

                  {!isTurnover && !isSubtask && (
                    <InputField
                      label="Location of Problem"
                      name="location"
                      id="location"
                      dataCy="location"
                      type="text"
                      placeholder="eg. Hall, Kitchen"
                      value={formData.location}
                      onChange={(e) =>
                        setFormData({ ...formData, location: e.target.value })
                      }
                      errorText={formErrors.location}
                      style="!w-full"
                    />
                  )}

                  <InputSelectField
                    label="Priority"
                    name="priority"
                    id="priority"
                    dataCy="priority"
                    placeholder="Select Priority"
                    options={priorityOptions}
                    value={formData.priority}
                    onChange={(e) =>
                      setFormData({ ...formData, priority: e.target.value })
                    }
                    errorText={formErrors.priority}
                    style="!w-full"
                  />

                  <AssigneSelectField
                    label="Assign To"
                    name="assignto"
                    id="assignto"
                    dataCy="assignTo"
                    placeholder="Select Assign To"
                    options={assignToOptions}
                    value={formData.assignToIndex}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        assignTo: assignToOptions[e.target.value],
                        assignToIndex: e.target.value,
                      });
                    }}
                    errorText={formErrors.assignTo}
                    style="!w-full"
                  />

                  {isTurnover && (
                    <InputDateField
                      label="Turnover Start Date"
                      name="turnoverStartDate"
                      id="turnoverStartDate"
                      dataCy="turnoverStartDate"
                      minDate={new Date()}
                      value={turnOverFormData.turnoverStartDate}
                      onChange={(value) =>
                        setTurnOverFormData({
                          ...turnOverFormData,
                          turnoverStartDate: value,
                        })
                      }
                      style="!w-full"
                      errorText={formErrors.turnoverStartDate}
                    />
                  )}

                  {isTurnover && (
                    <InputDateField
                      label="Turnover End Date"
                      name="turnoverEndDate"
                      id="turnoverEndDate"
                      dataCy="turnoverEndDate"
                      minDate={new Date()}
                      value={turnOverFormData.turnoverEndDate}
                      onChange={(value) =>
                        setTurnOverFormData({
                          ...turnOverFormData,
                          turnoverEndDate: value,
                        })
                      }
                      style="!w-full"
                      errorText={formErrors.turnoverEndDate}
                    />
                  )}

                  {!isTurnover && !isSubtask && (
                    <InputFileField
                      label="Attach Media"
                      name="media"
                      id="media"
                      dataCy="media"
                      accept=".png, .jpg, .jpeg, .gif, .webp, .mp4, .ogg, .webm"
                      placeholder="Select Media Files"
                      multiple
                      maxSizeinMB={10}
                      notImp={true}
                      value={media}
                      setValue={setMedia}
                      style="!w-full"
                      hintText="Each Media size should be less than 10MB"
                    />
                  )}

                  {!isTurnover && !isSubtask && (
                    <InputTextArea
                      label="Notes"
                      name="mediaNotes"
                      id="mediaNotes"
                      dataCy="mediaNotes"
                      type="text"
                      notImp
                      placeholder="Enter Notes here..."
                      value={mediaNotes}
                      onChange={(e) =>
                        setMediaNotes(e.target.value.replace(/[^\w\s]/gi, ""))
                      }
                      errorText={""}
                      style="!w-full"
                      className="h-20 rounded-lg"
                    />
                  )}

                  {!isTurnover && (
                    <InputObjectSelectField
                      label="Vendor"
                      name="vendor"
                      id="vendor"
                      dataCy="vendor"
                      placeholder="Select Vendor"
                      notImp
                      value={formData.vendorId}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          vendorId: parseInt(e.target.value),
                        })
                      }
                      options={vendorsOptions}
                      optionValue="roleId"
                      optionName="name"
                      errorText={formErrors.vendorId}
                      style="!w-full"
                    />
                  )}

                  {!isTurnover && (
                    <>
                      <span
                        id="availabilityTimeSlotsHeader"
                        className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-full col-span-2"
                      >
                        Availibility Time Slots
                      </span>

                      <InputDateAndTimeRangeField
                        label="Available Time Slot 1"
                        name="availability1"
                        id="availability1"
                        dataCy="availability1"
                        value={availability1}
                        setValue={setAvailability1}
                        hintText="Minimum of 2 hours slot is required!"
                        errorText={formErrors.availability1}
                        style="!w-[23rem] md:col-span-2"
                      />

                      <InputDateAndTimeRangeField
                        label="Available Time Slot 2"
                        name="availability2"
                        id="availability2"
                        dataCy="availability2"
                        value={availability2}
                        setValue={setAvailability2}
                        hintText="Minimum of 2 hours slot is required!"
                        errorText={formErrors.availability2}
                        style="!w-[23rem] md:col-span-2"
                      />

                      <InputDateAndTimeRangeField
                        label="Available Time Slot 3"
                        name="availability3"
                        id="availability3"
                        dataCy="availability3"
                        hidden={!addTimeSlot3}
                        notImp={true}
                        value={availability3}
                        setValue={setAvailability3}
                        hintText="Minimum of 2 hours slot is required!"
                        errorText={formErrors.availability3}
                        style="!w-[23rem] md:col-span-2"
                      />
                    </>
                  )}
                </div>
                {!isTurnover && (
                  <div className="mt-6 mb-4 flex justify-center items-center">
                    <button
                      id="addSlot3"
                      name="addSlot3"
                      data-cy="addSlot3"
                      onClick={(e) => {
                        e.preventDefault();
                        setAddTimeSlot3(true);
                      }}
                      className={
                        addTimeSlot3
                          ? "hidden"
                          : "flex justify-center items-center gap-1 w-fit h-10 px-2 text-gray-400 font-medium border border-gray-400 rounded shadow focus:shadow-lg hover:shadow-lg hover:text-white focus:text-white hover:bg-blue-53 focus:bg-blue-53"
                      }
                    >
                      <AiOutlinePlusCircle />
                      Add Additional Availibility
                    </button>

                    <button
                      id="removeSlot3"
                      name="removeSlot3"
                      data-cy="removeSlot3"
                      onClick={handleRemoveAvailablity3}
                      className={
                        !addTimeSlot3
                          ? "hidden"
                          : "mt-2 flex justify-center items-center  gap-2 w-fit h-10 px-2 text-gray-400 font-medium border border-gray-400 rounded shadow focus:shadow-lg hover:shadow-lg hover:text-white focus:text-white hover:bg-red-500 focus:bg-red-500"
                      }
                    >
                      <AiOutlineMinusCircle /> Remove Additional Availibility
                    </button>
                  </div>
                )}
                <div className="flex gap-16 items-center mt-12 mb-12">
                  <ButtonGrayOutline
                    id="back"
                    btnName="back"
                    dataCy="back"
                    name="Back"
                    onClick={(e) => handleBack(e)}
                    hidden={apiProcessing.loader}
                    className="justify-self-end"
                  />

                  <ButtonBlue
                    id="createRequest"
                    btnName="createRequest"
                    dataCy="createRequest"
                    name="Create Request"
                    onClick={(e) => handleSteps(e)}
                    className="!w-fit"
                  />
                </div>
              </>
            ) : null}

            {activeStep === 2 ? (
              <>
                <div className="flex flex-col justify-center items-center mt-12">
                  <BsFillCheckCircleFill
                    id="successIcon"
                    className="w-14 h-12 text-green-500 mb-4"
                  />

                  <p
                    id="sucessMessage"
                    className="font-semibold text-lg text-center"
                  >
                    A {isTurnover && "Turnover"} {isSubtask && "Subtask"}
                    {!isTurnover && !isSubtask && "Maintenance Work Order"} has
                    been successfully Created! <br />
                    Your Work Order Id is{" "}
                    <span className="font-bold text-lg">{ticketId}</span>
                  </p>
                  <ButtonGrayOutline
                    id="close"
                    btnName="close"
                    dataCy="close"
                    name="Close"
                    onClick={(e) => props.setTrigger(false)}
                    className="mt-6"
                  />
                </div>
              </>
            ) : null}
          </form>
        </DialogContent>
      </Dialog>
    </>
  ) : null;
};

export default CreateMaintenanceRequest;

export const AssigneSelectField = (props) => {
  return (
    <>
      <label
        id={props.id + "Title"}
        htmlFor={props.name}
        hidden={props.hidden}
        className={props.style + " text-gray-700 w-80 text-sm font-semibold"}
      >
        {props.label}
        <span hidden={props.notImp} className="text-red-600">
          *
        </span>
        <select
          name={props.name}
          id={props.id + "Select"}
          data-cy={props.dataCy}
          required={!props.notImp}
          value={props.value}
          onChange={props.onChange}
          className={
            props.className +
            " mt-1 p-2.5 w-full bg-gray-50 border text-gray-900 text-sm focus:border-blue-52"
          }
        >
          <option id="placeHolder" value="" disabled>
            {props.placeholder}
          </option>
          {props.options.length > 0 ? (
            <>
              {props.options.map((option, index) => (
                <>
                  <option id={index} key={index.toString()} value={index}>
                    {option.name}
                  </option>
                </>
              ))}
            </>
          ) : (
            <option id="noOptions">No options</option>
          )}
        </select>
        <span
          id="errorText"
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm"
        >
          {props.errorText || ""}
        </span>
        <span
          id="hintText"
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100"
        >
          {props.hintText || ""}
        </span>
      </label>
    </>
  );
};
